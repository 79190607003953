import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import React, { useState } from 'react';
import { Checkbox } from '@mui/material';
import Iconify from '#/components/shared/ui/Iconify';
import CustomPopover from '#/components/shared/custom-popover/custom-popover';
import useBoolean from '#/hooks/useBoolean';
import usePopover from '#/hooks/usePopover';
import DeleteConfirmation from '#/components/pages/Admin/consulting-legal-table/DeleteConfirmation/DeleteConfirmation';
import {
  invalidatePartnerQueries,
  useDeleteAdvisor,
  useEditAdvisor,
} from '#/api/partnerQueries';
import EditableRow from '#/components/pages/Admin/consulting-legal-table/EditRow/EditableRow';
import useLocales from '#/hooks/useLocales';

type Props = {
  selected: boolean;
  row: any;
  onSelectRow?: VoidFunction;
  fromDefaultList?: boolean;
  isPublicView?: boolean;
};

export default function LegalTableRow({
  row,
  selected,
  onSelectRow,
  fromDefaultList,
  isPublicView,
}: Props) {
  const [enableEdit, setEnableEdit] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const { advisor_name, id } = row;

  const { mutateAsync: deleteAdvisor } = useDeleteAdvisor(id);
  const { mutateAsync: updateAdvisor } = useEditAdvisor();

  const handleOpenDeleteConfirmation = () => setOpenDeleteConfirmation(true);
  const handleCloseDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const handleEnableEdit = () => setEnableEdit((prev) => !prev);

  const confirm = useBoolean();

  // const quickEdit = useBoolean();

  const popover = usePopover();

  const onDeleteRow = async () => {
    await deleteAdvisor(id).then(() => {
      invalidatePartnerQueries.advisorsList();
      handleCloseDeleteConfirmation();
    });
  };

  const onEdit = async (values: any) => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('logo', values.logo);
    formData.append('advisor_name', values.advisor_name);
    formData.append('email', values.email);
    formData.append('location', values.location);

    // @ts-ignore
    await updateAdvisor(formData).then(() => {
      invalidatePartnerQueries.advisorsList();
      handleEnableEdit();
    });
  };

  const { translate } = useLocales();

  return (
    <>
      {enableEdit ? (
        <EditableRow
          initialValues={row}
          onSubmit={onEdit}
          onEditChange={handleEnableEdit}
        />
      ) : (
        <ReadOnlyRow
          row={row}
          selected={selected}
          popover={popover}
          fromDefaultList={fromDefaultList}
          isPublicView={isPublicView}
          onSelectRow={onSelectRow}
        />
      )}
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            handleEnableEdit();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {String(translate('global.edit'))}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteConfirmation();
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {String(translate('global.delete'))}
        </MenuItem>
      </CustomPopover>
      <DeleteConfirmation
        open={openDeleteConfirmation}
        closeDialog={handleCloseDeleteConfirmation}
        consultantInfo={{
          name: advisor_name,
        }}
        onConfirm={onDeleteRow}
      />
    </>
  );
}

type ReadOnlyRowProps = {
  row: any;
  selected: boolean;
  fromDefaultList?: boolean;
  isPublicView?: boolean;
  popover: ReturnType<typeof usePopover>;
  onSelectRow?: VoidFunction;
};

const ReadOnlyRow = ({
  row,
  selected,
  fromDefaultList,
  isPublicView = false,
  popover,
  onSelectRow,
}: ReadOnlyRowProps) => {
  const { advisor_name, location, email, added, logo } = row;
  return (
    <TableRow hover selected={selected}>
      {isPublicView && (
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
      )}
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={advisor_name} src={logo} sx={{ mr: 2 }} />
        <ListItemText
          primary={advisor_name}
          // secondary={email}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            component: 'span',
            color: 'text.disabled',
          }}
        />
      </TableCell>

      {!isPublicView && (
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{email}</TableCell>
      )}
      <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
        {location}
      </TableCell>

      {isPublicView && (
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{added}</TableCell>
      )}

      {!fromDefaultList && (
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};
