import React, { useCallback, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {
  // MenuItem,
  // Select,
  Box,
  Stack,
  TextField,
  Tooltip,
  Autocomplete,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Iconify from '#/components/shared/ui/Iconify';
import cityData from '#/components/pages/Admin/consulting-legal-table/AddConsultant/cityData';
import useLocales from '#/hooks/useLocales';
import { UploadAvatar } from '#/components/shared/upload';

type ValuesType = {
  advisor_name: string;
  email: string;
  location: string;
  logo: string;
};

type EditableRowProps = {
  initialValues: ValuesType;
  onSubmit: (values: ValuesType) => void;
  onEditChange: () => void;
};

const EditableRow = ({
  initialValues,
  onSubmit,
  onEditChange,
}: EditableRowProps) => {
  const [values, setValues] = useState<ValuesType>(initialValues);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name as keyof ValuesType]: value,
    }));
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        // @ts-ignore
        setValues((prev) => ({
          ...prev,
          logo: newFile,
        }));
      }
    },
    [setValues]
  );

  const fields: Array<{ name: keyof ValuesType; placeholder: string }> = [
    { name: 'logo', placeholder: 'Logo' },
    { name: 'advisor_name', placeholder: 'Advisor Name' },
    { name: 'email', placeholder: 'Email' },
    { name: 'location', placeholder: 'Standort' },
  ];

  const { translate } = useLocales();

  return (
    <TableRow>
      {fields
        .filter((field) => field.name !== 'logo')
        .map((field) => (
          <TableCell key={field.name}>
            {field.name === 'location' ? (
              // <Select
              //   name={field.name}
              //   value={values[field.name]}
              //   // @ts-ignore
              //   onChange={handleOnChange}
              //   size="small"
              //   sx={{
              //     width: '100%',
              //   }}
              // >
              //   {cityData.map((city) => (
              //     <MenuItem key={city.label} value={city.value}>
              //       {city.label}
              //     </MenuItem>
              //   ))}
              // </Select>
              <Autocomplete
                options={cityData}
                size="small"
                value={cityData.find((city) => city.value === values.location)}
                onChange={(_e, value) => {
                  // @ts-ignore
                  setValues((prev) => ({
                    ...prev,
                    location: value?.value,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={field.name}
                    value={values[field.name]}
                    variant="outlined"
                    label={String(translate('global.formLabels.location'))}
                  />
                )}
              />
            ) : (
              <Box>
                {field.name === 'advisor_name' ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <UploadAvatar
                      accept={{
                        'image/*': [],
                      }}
                      file={values.logo}
                      sx={{ width: 40, height: 40 }}
                      onDrop={handleDrop}
                    />
                    <TextField
                      size="small"
                      placeholder={field.placeholder}
                      name={field.name}
                      value={values[field.name]}
                      onChange={handleOnChange}
                    />
                  </Stack>
                ) : (
                  <TextField
                    size="small"
                    placeholder={field.placeholder}
                    name={field.name}
                    value={values[field.name]}
                    onChange={handleOnChange}
                    fullWidth
                  />
                )}
              </Box>
            )}
          </TableCell>
        ))}
      <TableCell />
      <TableCell>
        <Stack direction="row" spacing={1}>
          <Tooltip title={String(translate('global.save'))}>
            <IconButton onClick={() => onSubmit(values)}>
              <Iconify icon="eva:save-fill" color="primary.main" />
            </IconButton>
          </Tooltip>
          <Tooltip title={String(translate('global.cancel'))}>
            <IconButton onClick={onEditChange}>
              <Iconify icon="eva:close-fill" color="error.main" />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default EditableRow;
