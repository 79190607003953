const sortingModel = [
  {
    field: 'name',
    sort_by: {
      asc: 'advisor_name',
      desc: '-advisor_name',
    },
  },
  {
    field: 'email',
    sort_by: {
      asc: 'email',
      desc: '-email',
    },
  },
  {
    field: 'location',
    sort_by: {
      asc: 'location',
      desc: '-location',
    },
  },
];

export default sortingModel;
