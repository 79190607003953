const cityData = [
  { value: 'aarau', label: 'Aarau' },
  { value: 'aarberg', label: 'Aarberg' },
  { value: 'aarburg', label: 'Aarburg' },
  { value: 'adliswil', label: 'Adliswil' },
  { value: 'aesch (bl)', label: 'Aesch (BL)' },
  { value: 'affoltern am albis', label: 'Affoltern am Albis' },
  { value: 'agno', label: 'Agno' },
  { value: 'aigle', label: 'Aigle' },
  { value: 'allschwil', label: 'Allschwil' },
  { value: 'altdorf (ur)', label: 'Altdorf (UR)' },
  { value: 'altstätten', label: 'Altstätten' },
  { value: 'amriswil', label: 'Amriswil' },
  { value: 'appenzell', label: 'Appenzell' },
  { value: 'arbon', label: 'Arbon' },
  { value: 'arlesheim', label: 'Arlesheim' },
  { value: 'arosa', label: 'Arosa' },
  { value: 'arth', label: 'Arth' },
  { value: 'ascona', label: 'Ascona' },
  { value: 'aubonne', label: 'Aubonne' },
  { value: 'avenches', label: 'Avenches' },
  { value: 'baar', label: 'Baar' },
  { value: 'baden', label: 'Baden' },
  { value: 'basel', label: 'Basel' },
  { value: 'bassersdorf', label: 'Bassersdorf' },
  { value: 'bellinzona', label: 'Bellinzona' },
  { value: 'belp', label: 'Belp' },
  { value: 'bern', label: 'Bern' },
  { value: 'beromünster', label: 'Beromünster' },
  { value: 'biasca', label: 'Biasca' },
  { value: 'biel/bienne', label: 'Biel/Bienne' },
  { value: 'binningen', label: 'Binningen' },
  { value: 'birsfelden', label: 'Birsfelden' },
  { value: 'bischofszell', label: 'Bischofszell' },
  { value: 'boudry', label: 'Boudry' },
  { value: 'bourg-saint-pierre', label: 'Bourg-Saint-Pierre' },
  { value: 'bremgarten (ag)', label: 'Bremgarten (AG)' },
  { value: 'brig-glis', label: 'Brig-Glis' },
  { value: 'brugg', label: 'Brugg' },
  { value: 'buchs (sg)', label: 'Buchs (SG)' },
  { value: 'bülach', label: 'Bülach' },
  { value: 'büren a.a.', label: 'Büren a.A.' },
  { value: 'bulle', label: 'Bulle' },
  { value: 'burgdorf', label: 'Burgdorf' },
  { value: 'bussigny', label: 'Bussigny' },
  { value: 'carouge (ge)', label: 'Carouge (GE)' },
  { value: 'cham', label: 'Cham' },
  { value: 'châtel-saint-denis', label: 'Châtel-Saint-Denis' },
  { value: 'chêne-bougeries', label: 'Chêne-Bougeries' },
  { value: 'chiasso', label: 'Chiasso' },
  { value: 'chur', label: 'Chur' },
  { value: 'conthey', label: 'Conthey' },
  { value: 'coppet', label: 'Coppet' },
  { value: 'cossonay', label: 'Cossonay' },
  { value: 'croglio', label: 'Croglio' },
  { value: 'crissier', label: 'Crissier' },
  { value: 'cudrefin', label: 'Cudrefin' },
  { value: 'cully', label: 'Cully' },
  { value: 'davos', label: 'Davos' },
  { value: 'delémont', label: 'Delémont' },
  { value: 'diessenhofen', label: 'Diessenhofen' },
  { value: 'dietikon', label: 'Dietikon' },
  { value: 'dübendorf', label: 'Dübendorf' },
  { value: 'ebikon', label: 'Ebikon' },
  { value: 'échallens', label: 'Échallens' },
  { value: 'ecublens (vd)', label: 'Ecublens (VD)' },
  { value: 'eglisau', label: 'Eglisau' },
  { value: 'einsiedeln', label: 'Einsiedeln' },
  { value: 'elgg', label: 'Elgg' },
  { value: 'emmen', label: 'Emmen' },
  { value: 'erlach', label: 'Erlach' },
  { value: 'estavayer-le-lac', label: 'Estavayer-le-Lac' },
  { value: 'flawil', label: 'Flawil' },
  { value: 'frauenfeld', label: 'Frauenfeld' },
  { value: 'freienbach', label: 'Freienbach' },
  { value: 'fribourg', label: 'Fribourg' },
  { value: 'geneva', label: 'Geneva' },
  { value: 'gland', label: 'Gland' },
  { value: 'glarus', label: 'Glarus' },
  { value: 'glarus nord', label: 'Glarus Nord' },
  { value: 'gordola', label: 'Gordola' },
  { value: 'gossau (sg)', label: 'Gossau (SG)' },
  { value: 'grandcour', label: 'Grandcour' },
  { value: 'grandson', label: 'Grandson' },
  { value: 'greifensee', label: 'Greifensee' },
  { value: 'grenchen', label: 'Grenchen' },
  { value: 'grüningen', label: 'Grüningen' },
  { value: 'gruyères', label: 'Gruyères' },
  { value: 'herisau', label: 'Herisau' },
  { value: 'hermance', label: 'Hermance' },
  { value: 'hinwil', label: 'Hinwil' },
  { value: 'horgen', label: 'Horgen' },
  { value: 'horw', label: 'Horw' },
  { value: 'huttwil', label: 'Huttwil' },
  { value: 'ilanz', label: 'Ilanz' },
  { value: 'illnau-effretikon', label: 'Illnau-Effretikon' },
  { value: 'interlaken', label: 'Interlaken' },
  { value: 'ittigen', label: 'Ittigen' },
  { value: 'kaiserstuhl (ag)', label: 'Kaiserstuhl (AG)' },
  { value: 'klingnau', label: 'Klingnau' },
  { value: 'kloten', label: 'Kloten' },
  { value: 'köniz', label: 'Köniz' },
  { value: 'kreuzlingen', label: 'Kreuzlingen' },
  { value: 'kriens', label: 'Kriens' },
  { value: 'küsnacht (zh)', label: 'Küsnacht (ZH)' },
  { value: 'la chaux-de-fonds', label: 'La Chaux-de-Fonds' },
  { value: 'la neuveville', label: 'La Neuveville' },
  { value: 'la sarraz', label: 'La Sarraz' },
  { value: 'la tour-de-peilz', label: 'La Tour-de-Peilz' },
  { value: 'la tour-de-trême', label: 'La Tour-de-Trême' },
  { value: 'lachen (sz)', label: 'Lachen (SZ)' },
  { value: 'lancy', label: 'Lancy' },
  { value: 'langenthal', label: 'Langenthal' },
  { value: 'laufen (bl)', label: 'Laufen (BL)' },
  { value: 'laufenburg', label: 'Laufenburg' },
  { value: 'laupen', label: 'Laupen' },
  { value: 'lausanne', label: 'Lausanne' },
  { value: 'le grand-saconnex', label: 'Le Grand-Saconnex' },
  { value: 'le landeron', label: 'Le Landeron' },
  { value: 'le locle', label: 'Le Locle' },
  { value: 'lenzburg', label: 'Lenzburg' },
  { value: 'les clées', label: 'Les Clées' },
  { value: 'leuk', label: 'Leuk' },
  { value: 'lichtensteig', label: 'Lichtensteig' },
  { value: 'liestal', label: 'Liestal' },
  { value: 'locarno', label: 'Locarno' },
  { value: 'losone', label: 'Losone' },
  { value: 'lugano', label: 'Lugano' },
  { value: 'lutry', label: 'Lutry' },
  { value: 'lucerne', label: 'Lucerne' },
  { value: 'lyss', label: 'Lyss' },
  { value: 'männedorf', label: 'Männedorf' },
  { value: 'maienfeld', label: 'Maienfeld' },
  { value: 'martigny', label: 'Martigny' },
  { value: 'meilen', label: 'Meilen' },
  { value: 'mellingen', label: 'Mellingen' },
  { value: 'mendrisio', label: 'Mendrisio' },
  { value: 'meyrin', label: 'Meyrin' },
  { value: 'möhlin', label: 'Möhlin' },
  { value: 'monthey', label: 'Monthey' },
  { value: 'montreux', label: 'Montreux' },
  { value: 'morcote', label: 'Morcote' },
  { value: 'morges', label: 'Morges' },
  { value: 'moudon', label: 'Moudon' },
  { value: 'moutier', label: 'Moutier' },
  { value: 'münchenbuchsee', label: 'Münchenbuchsee' },
  { value: 'münchenstein', label: 'Münchenstein' },
  { value: 'münsingen', label: 'Münsingen' },
  { value: 'muri bei bern', label: 'Muri bei Bern' },
  { value: 'murten', label: 'Murten' },
  { value: 'muttenz', label: 'Muttenz' },
  { value: 'neuchâtel', label: 'Neuchâtel' },
  { value: 'neuhausen am rheinfall', label: 'Neuhausen am Rheinfall' },
  { value: 'neunkirch', label: 'Neunkirch' },
  { value: 'nidau', label: 'Nidau' },
  { value: 'nyon', label: 'Nyon' },
  { value: 'oberwil (bl)', label: 'Oberwil (BL)' },
  { value: 'oftringen', label: 'Oftringen' },
  { value: 'olten', label: 'Olten' },
  { value: 'onex', label: 'Onex' },
  { value: 'opfikon', label: 'Opfikon' },
  { value: 'orbe', label: 'Orbe' },
  { value: 'orsières', label: 'Orsières' },
  { value: 'ostermundigen', label: 'Ostermundigen' },
  { value: 'payerne', label: 'Payerne' },
  { value: 'peseux', label: 'Peseux' },
  { value: 'pfäffikon', label: 'Pfäffikon' },
  { value: 'plan-les-ouates', label: 'Plan-les-Ouates' },
  { value: 'porrentruy', label: 'Porrentruy' },
  { value: 'pratteln', label: 'Pratteln' },
  { value: 'prilly', label: 'Prilly' },
  { value: 'pully', label: 'Pully' },
  { value: 'rapperswil-jona', label: 'Rapperswil-Jona' },
  { value: 'regensberg', label: 'Regensberg' },
  { value: 'regensdorf', label: 'Regensdorf' },
  { value: 'reinach (bl)', label: 'Reinach (BL)' },
  { value: 'renens (vd)', label: 'Renens (VD)' },
  { value: 'rheinau', label: 'Rheinau' },
  { value: 'rheineck', label: 'Rheineck' },
  { value: 'rheinfelden', label: 'Rheinfelden' },
  { value: 'richterswil', label: 'Richterswil' },
  { value: 'riehen', label: 'Riehen' },
  { value: 'risch', label: 'Risch' },
  { value: 'riva san vitale', label: 'Riva San Vitale' },
  { value: 'rolle', label: 'Rolle' },
  { value: 'romainmôtier', label: 'Romainmôtier' },
  { value: 'romanshorn', label: 'Romanshorn' },
  { value: 'romont (fr)', label: 'Romont (FR)' },
  { value: 'rorschach', label: 'Rorschach' },
  { value: 'rue', label: 'Rue' },
  { value: 'rüti (zh)', label: 'Rüti (ZH)' },
  { value: 'saillon', label: 'Saillon' },
  { value: 'saint-maurice', label: 'Saint-Maurice' },
  { value: 'saint-prex', label: 'Saint-Prex' },
  { value: 'saint-ursanne', label: 'Saint-Ursanne' },
  { value: 'sargans', label: 'Sargans' },
  { value: 'sarnen', label: 'Sarnen' },
  { value: 'schaffhausen', label: 'Schaffhausen' },
  { value: 'schlieren', label: 'Schlieren' },
  { value: 'schwyz', label: 'Schwyz' },
  { value: 'sembrancher', label: 'Sembrancher' },
  { value: 'sempach', label: 'Sempach' },
  { value: 'sierre', label: 'Sierre' },
  { value: 'sion', label: 'Sion' },
  { value: 'solothurn', label: 'Solothurn' },
  { value: 'spiez', label: 'Spiez' },
  { value: 'spreitenbach', label: 'Spreitenbach' },
  { value: 'splügen', label: 'Splügen' },
  { value: 'st. gallen', label: 'St. Gallen' },
  { value: 'st. moritz', label: 'St. Moritz' },
  { value: 'stäfa', label: 'Stäfa' },
  { value: 'stans', label: 'Stans' },
  { value: 'steckborn', label: 'Steckborn' },
  { value: 'steffisburg', label: 'Steffisburg' },
  { value: 'steinhausen', label: 'Steinhausen' },
  { value: 'suhr', label: 'Suhr' },
  { value: 'stein am rhein', label: 'Stein am Rhein' },
  { value: 'sursee', label: 'Sursee' },
  { value: 'thalwil', label: 'Thalwil' },
  { value: 'thônex', label: 'Thônex' },
  { value: 'thun', label: 'Thun' },
  { value: 'thusis', label: 'Thusis' },
  { value: 'unterseen', label: 'Unterseen' },
  { value: 'urdorf', label: 'Urdorf' },
  { value: 'uster', label: 'Uster' },
  { value: 'uznach', label: 'Uznach' },
  { value: 'uzwil', label: 'Uzwil' },
  { value: 'val-de-travers', label: 'Val-de-Travers' },
  { value: 'valangin', label: 'Valangin' },
  { value: 'vernier', label: 'Vernier' },
  { value: 'versoix', label: 'Versoix' },
  { value: 'veyrier', label: 'Veyrier' },
  { value: 'villeneuve', label: 'Villeneuve' },
  { value: 'villars-sur-glâne', label: 'Villars-sur-Glâne' },
  { value: 'visp', label: 'Visp' },
  { value: 'volketswil', label: 'Volketswil' },
  { value: 'wädenswil', label: 'Wädenswil' },
  { value: 'waldenburg', label: 'Waldenburg' },
  { value: 'walenstadt', label: 'Walenstadt' },
  { value: 'wallisellen', label: 'Wallisellen' },
  { value: 'wangen an der aare', label: 'Wangen an der Aare' },
  { value: 'werdenberg', label: 'Werdenberg' },
  { value: 'weinfelden', label: 'Weinfelden' },
  { value: 'wettingen', label: 'Wettingen' },
  { value: 'wetzikon (zh)', label: 'Wetzikon (ZH)' },
  { value: 'wiedlisbach', label: 'Wiedlisbach' },
  { value: 'wil (sg)', label: 'Wil (SG)' },
  { value: 'willisau', label: 'Willisau' },
  { value: 'winterthur', label: 'Winterthur' },
  { value: 'wohlen (ag)', label: 'Wohlen (AG)' },
  { value: 'yverdon-les-bains', label: 'Yverdon-les-Bains' },
  { value: 'zermatt', label: 'Zermatt' },
  { value: 'zofingen', label: 'Zofingen' },
  { value: 'zollikofen', label: 'Zollikofen' },
  { value: 'zollikon', label: 'Zollikon' },
  { value: 'zug', label: 'Zug' },
  { value: 'zürich', label: 'Zürich' },
  { value: 'bad zurzach', label: 'Bad Zurzach' },
];

export default cityData;
